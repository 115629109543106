:root {
    --main-radius: 0px;
    --main-padding: 0.6rem;
    --big-padding: 2rem;
    --main-white: whitesmoke;
    --main-black: #05101D;
    --main-blue: #00FFB2;
  }

  
  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


  @font-face {
    font-family: 'SpaceMono';
    src: url('fonts/SpaceMono-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SpaceMono';
    src: url('fonts/SpaceMono-Bold.ttf');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'SpaceMono';
    src: url('fonts/SpaceMono-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'SpaceMono';
    src: url('fonts/SpaceMono-Italic.ttf');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Mondwest';
    src: url('fonts/PPMondwest-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Terminal';
    src: url('fonts/terminal-grotesque-webfont.ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MisterPixel';
    src: url('fonts/Mister Pixel Regular.otf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MisterPixel';
    src: url('fonts/Mister Pixel Tools2.ttf');
    font-weight: bold;
    font-style: normal;
  }

  ::-moz-selection { /* Code for Firefox */
    color:  var(--main-black);
    background: var(--main-blue);
  }
  
  ::selection {
    color:  var(--main-black);
    background:  var(--main-blue);
  }

  .Blue{
    color:  var(--main-blue);
  }


  #loadOverlay{
    background-color:  var(--main-black);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10000;      
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;     
                    
  }

  @keyframes loadAnim {
    0%      {transform: translatey(0vh)}
    100%    {transform: translatey(100vh)}
  }

  .loadingBar{
    position:relative;
    top:50%;
    width:100%;
    height:5px;
    background:  var(--main-white);
    transform: scaleX(0);
    transform-origin: top left;
    z-index: 1000000;
  }


    /* hide everything on load */
    /* stop scroll on load */

  html{
    background:  var(--main-black);   
    scroll-behavior: smooth;                    
  }

  #html{
    overflow-y: hidden;  
  }

  body{
    margin: 0;
    font-family: SpaceMono;
  }
  
  h1{
    font-family: Mondwest;
    font-size: 96px;
    font-weight: normal;
    margin: 0px;
    letter-spacing: 1px;
    position: relative;
    top: 17%;

    display: inline-block;
    transform: translatey(200px);
  }

  span {
    display: inline-block;
    overflow: hidden;
  }

  .word-1 h1{
    
  }

  .word-2 h1{

  }
  .word-3 h1{

  }
  .word-4 p{

  }
  .word-5 p{

  }
  .word-6 p{

  }



  @keyframes swift-text {
    from{
      transform: translatey(120px);
    }
    to {
      transform: translatey(0px);
    }
  }

  @keyframes swift-ptext {
    from{
      transform: translatey(70px);
      opacity: 0%;
    }
    to {
      transform: translatey(0px);
      opacity: 100%;
    }
  }

  @keyframes swift-box {
    from{
        
      transform: translatey(520px);
    }
    to {
        
      transform: translatey(0px);
    }
  }

  .Clickable{

    font-family: Mondwest;
    font-size: 96px;
    font-weight: normal;
    margin: 0px;
    letter-spacing: 1px;
    position: relative;
    top: 17%;

    display: inline-block;
    transform: translatey(200px);

    font-weight: normal;
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-decoration-color:  var(--main-blue);
    text-underline-offset: 10px;
    letter-spacing: 2px;
    display: inline-block;
    
    
  }

  .Clickable:visited{
    font-family: Mondwest;
    font-size: 96px;
    font-weight: normal;
    margin: 0px;
    letter-spacing: 1px;
    position: relative;
    top: 17%;
    color: var(--main-white);

    display: inline-block;
    transform: translatey(200px);

    font-weight: normal;
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-decoration-color:  var(--main-blue);
    text-underline-offset: 10px;
    letter-spacing: 2px;
    display: inline-block;
  }

  .Clickable:hover{
    font-family: Mondwest;
    font-size: 96px;
    font-weight: normal;
    margin: 0px;
    letter-spacing: 1px;
    position: relative;
    top: 17%;
    color: var(--main-white);

    display: inline-block;
    transform: translatey(200px);

    font-weight: normal;
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-decoration-color:  var(--main-blue);
    text-underline-offset: 10px;
    letter-spacing: 2px;
    display: inline-block;
  }



  .vl{
    position:fixed;
    border-left: 1px solid  var(--main-white);
    height: calc(100% + 48px + 9.6px);
    left: 58.41%;
    margin-left: -3px;
    top: 0;
    z-index: -10;
    
  }

  .Clickable:hover{

  }

  .word-1, .word-2, .word-3{
    position:relative; 
    top: 17%
  }
  .word-4, .word-5, .word-6{
    position:relative; 
    top: 27%
  }

  #word12, #word13{
    font-size:32px;
  }
  #word2{
    top: -45px; 
    padding-left: 10px;
  }
  #word5{
    padding-top: 200px;
  }


  .Star{

    font-family: Terminal;  
    font-size: 104px;
    color:  var(--main-blue);
    display: inline-block;
    transform: translatey(100px);
  }

  .Star2{
    position:absolute;
    top: 105%;
    font-family: Terminal; 
    display: inline; 
    font-size: 104px;
    color:  var(--main-blue);
    
  }

  .Symbols{
    margin-top: 40px;
    font-family: MisterPixel;
    font-size: 52px;
    letter-spacing: 35px;
  }

  .Symbols-static{
    margin-top: 40px;
    top:-3px;
    right: -1px;
    font-family: MisterPixel;
    font-size: 52px;
    letter-spacing: 35px;
    transform: translatey(0px);
  }

  ul{
    list-style: none;
    font-size: 22px;
  }
  ul li::before{
    color:  var(--main-blue);
    content: "▪";
    display: inline-block; 
    width: 1em;
    margin-left: 5px;
    list-style-type: square;
  }


  h2{
    font-family: Mondwest;
    font-size: 52px;
  }
  h3{
    display: inline-block;
    top: calc(50%-28px);
    margin-left: 40px;
    margin-top: 28px;
    margin-bottom: 28px;
    font-family: SpaceMono;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  p{
    font-family: SpaceMono;
    font-size: 32px;
    position: relative;
    top: 17%;

    display: inline-block;
    transform: translatey(100px);
  }
  a{
    font-family: SpaceMono;
    font-weight: normal;
    text-decoration: none;
    color:  var(--main-white);
    font-size: 20px;
  }

  a:visited{
    font-weight: bold;
    text-decoration: none;
    color:  var(--main-white);
  }

  a:hover{
    font-weight: bold;
    text-decoration: underline;
    color:  var(--main-blue);
  }


  .container {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 48px auto 80px 800px 64px auto 950px 64px; 
    grid-template-areas:
      "nav nav nav nav nav nav nav nav nav nav nav nav"
      "mainText mainText mainText mainText mainText mainText mainText illustration illustration illustration illustration illustration"
      "myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills"
      "skill1 skill1 skill1 skill1 skill2 skill2 skill2 skill2 skill3 skill3 skill3 skill3"
      "myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects"
      "projects projects projects projects projects projects projects projects projects projects projects projects"
      "footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer2 footer2 footer2 footer2 footer2"
      "footer footer footer footer footer footer footer footer3 footer3 footer3 footer3 footer3";
    grid-gap: 0px;
    color:  var(--main-white);
  }

  #spanProject1{
    margin-top: -40px;
  }
  #spanProject2{
    margin-top: -60px;
  }
  


  .skillBox{
    height: 500px;
    border-style                :   solid;
    border-width                :   1px;
    border-color                :   var(--main-white);
    border-radius               :   32px;
    transform: translatey(520px);
    background:  var(--main-black);
  }

  .skillBoxTitle{
    height: 120px;
    border-bottom: 1px solid;
    
  }

  #scrollTo{
    position: absolute;
    top: calc(97vh);
  }

  li{
    margin: 8px;
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 32px;
    padding-bottom: 6px;
  }
  ul{
    padding: 14px;
    padding-top: 2px;
  }

  .project{
    padding-left: var(--big-padding);
    border-top: 1px solid;
    position:relative;
    width: 100%;
    background-color:  var(--main-black);
    display: inline-block;
    transform: translatey(200px);
  }

  .projectActivated{
    padding-left: var(--big-padding);
    border-top: 1px solid;
    position:relative;
    width: 100%;
    background-color:  var(--main-black);
    transition: 0.5s;
    transform: translatey(60px);
  }

  .projectActivated:hover{
    transform: translatey(0px);
    transition: 0.1s;
  }

  .projectTitle{
    
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translatey(0px);
  }

  .projectTag{
    font-size: 22px;
    padding-left: var(--main-padding);
    padding-right: var(--main-padding);
    margin-bottom: 10px;
    margin-right: var(--main-padding);
    border-radius:32px;
    font-family: SpaceMono;
    border: 1px solid  var(--main-white);
    display: inline-block;
    width: auto;
  }

  #number{
    display: inline; 
    top:-40px;
  }

  .button{
    border: 1px solid;
    border-radius: 32px;
    width: auto;
  }

  .button:hover{
    background-color: var(--main-white);
    color:  var(--main-black);
    transform: translatey(-32px);
    transition: 0.1s;
  }

  #word9{
    margin-top:100px;
  }

  nav {
    box-sizing: border-box;
    width:100%;
    height: 48px;
    background-color:  var(--main-black);
    grid-area: nav;
    padding-top: var(--main-padding);
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    border-bottom: 1px solid;
    position: fixed;
    
  }

  #mainText {
    grid-area: mainText;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    height:100vh;
    min-height: 900px;
  }

  #illustration {
    grid-area: illustration;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    height:100vh;
    min-height: 900px;
  }

  #myskills {
    grid-area: myskills;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
  }

  #skill1 {
    grid-area: skill1;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    padding-bottom:120px;
    display: inline-block;
    overflow: hidden;
  }

  #skill2 {
    grid-area: skill2;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    padding-bottom:120px;
    display: inline-block;
    overflow: hidden;
  }

  #skill3 {
    grid-area: skill3;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    padding-bottom:120px;
    display: inline-block;
    overflow: hidden;
  }

  #myprojects {
    grid-area: myprojects;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
  }

  #projects {
    background:  var(--main-black);
    grid-area: projects;
    margin: 0px;
    padding-top: 60px;
    height:980px;
  }

  #footer1 {
    background:  var(--main-black);
    grid-area: footer1;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    border-top: 1px solid;
  }
  #footer2 {
    background:  var(--main-black);
    grid-area: footer2;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    padding-top: var(--main-padding);
    border-top: 1px solid;
    border-left: 1px solid;
  }

  footer {
    background:  var(--main-black);
    grid-area: footer;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    border-top: 1px solid;
  }

  #footer3 {
    background:  var(--main-black);
    grid-area: footer3;
    padding-left: var(--big-padding);
    padding-right: var(--big-padding);
    border-top: 1px solid;
  }

  #social{
    text-decoration:underline; 
    padding-right: 120px;
  }


.webgl
{
    
    margin:0;
    padding:0;
    position: absolute;
    top: 10px;
    right: 0;
    outline: none;
    z-index: 0;
    pointer-events: none;
}

/* small computer */
@media only screen and (max-width: 1600px) 
{

  .container {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 48px auto 80px 800px 64px auto 50vw 64px; 
    grid-template-areas:
      "nav nav nav nav nav nav nav nav nav nav nav nav"
      "mainText mainText mainText mainText mainText mainText mainText illustration illustration illustration illustration illustration"
      "myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills"
      "skill1 skill1 skill1 skill1 skill2 skill2 skill2 skill2 skill3 skill3 skill3 skill3"
      "myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects"
      "projects projects projects projects projects projects projects projects projects projects projects projects"
      "footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer2 footer2 footer2 footer2 footer2"
      "footer footer footer footer footer footer footer footer3 footer3 footer3 footer3 footer3";
    grid-gap: 0px;
    color:  var(--main-white);
  }

  #number{
    top:-2vw;
    font-size:2vw;
  }

  #project7{
    padding-bottom: 23px;
  }
  
    h1{
        font-size: 5vw; 
    }

    .word-1, .word-3{
        position:relative; 
        top: 13%
    }
    .word-4, .word-5, .word-6{
        position:relative; 
        top: 23%
    }
    #word2{top:-2vw}

    #word12, #word13 {font-size:2vw}

    .Star {font-size: 5.5vw}

    .Symbols {font-size: 3.5vw}

    p {font-size: 2vw}

    a{font-size: 1.5vw}

    .word-2{top: 13%}

    .Clickable{
      font-size: 5vw; 
      position:relative; 
      top: 0%;
      margin:0;
    }
    .Clickable:visited{
      font-size: 5vw; 
      position:relative; 
      top: 0%;
      margin:0;
    }
    .Clickable:hover{
      font-size: 5vw; 
      position:relative; 
      top: 0%;
      margin:0;
    }

    h3 {
        font-size: 1.5vw;
        line-height: 2vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
        margin-left: 3vw;
    }
    .skillBoxTitle{
        height: 8vw;
    }
    .Symbols-static{
        font-size: 3.5vw;
        margin-top: 2.6vw;
        right: -10px;
    }
    #skill1, #skill2, #skill3{
        padding-left:1vw;
        padding-right:1vw;
        padding-bottom:1vw;
        height:40vw;
    }
    .skillBox{
        height:30vw;
    }

    li {
        font-size: 1.3vw;
        margin:0;
        line-height: 2vw;
    }

    .container {
        grid-template-rows: 48px auto 5vw 50vw 4vw auto 70vw 64px; 
    }

    #mainText, #illustration {min-height: 60vw;}

    #projects {
        height: auto;
        padding-top: 4vw;
    }
    #word4{margin-left:-2vw}
    #word5{padding-top:7vw}


}

@media only screen and (max-width: 1200px) 
{
    .webgl{
        top: -14vw;
        margin-top: 0px;
    }
}



/* phones */
@media only screen and (max-width: 900px) 
{
.container{
    grid-template-rows: 48px auto auto 10vw auto auto auto 10vw auto 90vw 70vw 64px; 
    grid-template-areas:
      "nav nav nav nav nav nav nav nav nav nav nav nav"
      "mainText mainText mainText mainText mainText mainText mainText mainText mainText mainText mainText mainText"
      "illustration illustration illustration illustration illustration illustration illustration illustration illustration illustration illustration illustration"
      "myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills myskills"
      "skill1 skill1 skill1 skill1 skill1 skill1 skill1 skill1 skill1 skill1 skill1 skill1"
      "skill2 skill2 skill2 skill2 skill2 skill2 skill2 skill2 skill2 skill2 skill2 skill2"
      "skill3 skill3 skill3 skill3 skill3 skill3 skill3 skill3 skill3 skill3 skill3 skill3"
      "myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects myprojects"
      "projects projects projects projects projects projects projects projects projects projects projects projects"
      "footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer1 footer1"
      "footer2 footer2 footer2 footer2 footer2 footer2 footer2 footer2 footer2 footer2 footer2 footer2"
      "footer footer footer footer footer footer footer footer3 footer3 footer3 footer3 footer3";
}

.webgl
{
    margin-top: 0px;
    top:75vw;
    left:0px;
}

#navElements{
    font-weight: bold;
}

h1{
    font-size: 8.2vw; 
}

.word-1, .word-2, .word-3{
    position:relative; 
    top: 6%
}
.word-4, .word-5, .word-6{
    position:relative; 
    top: 12%
}
#word2{top:-4vw}
#word3{font-size: 5vw;}

#word12, #word13 {font-size:3.5vw}

.Star {font-size: 11vw; left: -1px;}

.Symbols {
    font-size: 7.5vw;
    letter-spacing: 8vw;
    margin-top: 3vw;
}


.Clickable{

  font-family: Mondwest;
  font-size: 8.2vw; 
  font-weight: normal;
  margin: 0px;
  letter-spacing: 1px;
  position: relative;
  top: 17%;

  display: inline-block;
  transform: translatey(200px);

  font-weight: normal;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color:  var(--main-blue);
  text-underline-offset: 3px;
  letter-spacing: 2px;
  display: inline-block;
  
  
}

.Clickable:visited{
  font-family: Mondwest;
  font-size: 8.2vw; 
  font-weight: normal;
  margin: 0px;
  letter-spacing: 1px;
  position: relative;
  top: 17%;
  color: var(--main-white);

  display: inline-block;
  transform: translatey(200px);

  font-weight: normal;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color:  var(--main-blue);
  text-underline-offset: 3px;
  letter-spacing: 2px;
  display: inline-block;
}

.Clickable:hover{
  font-family: Mondwest;
  font-size: 8.2vw; 
  font-weight: normal;
  margin: 0px;
  letter-spacing: 1px;
  position: relative;
  top: 17%;
  color: var(--main-white);

  display: inline-block;
  transform: translatey(200px);

  font-weight: normal;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color:  var(--main-blue);
  text-underline-offset: 3px;
  letter-spacing: 2px;
  display: inline-block;
}

p {
    font-size: 3.5vw;
    line-height: 1.5vw;
    
}

a{font-size: 3vw}

.vl{
    border-left: 0px solid  var(--main-white);
}

h3 {
    font-size: 4.5vw;
    line-height: 6vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
    margin-left: 6vw;
}
.skillBoxTitle{
    height: 18vw;
}
.Symbols-static{
    font-size: 7vw;
    margin-top: 6.6vw;
    right: -2vw;
}
#skill1, #skill2, #skill3{
    padding-left:8vw;
    padding-right:8vw;
    padding-bottom:8vw;
    height: max-content;
}

#skill3{
    padding-bottom:16vw;
}
.skillBox{
    height:max-content;
}

li {
    font-size: 3.5vw;
    margin:0;
    line-height: 6vw;
}

.projectTitle{
  padding-left: 0vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  
}

.seeAllButton{
  font-weight: bold;
  border: 1px solid;
  border-radius: 32px;
  text-align: center;
  vertical-align: middle;
  padding: var(--main-padding);
  margin: 3.8vw;
  margin-bottom: 10vw;
}

.project{
  display: none;
}

  .projectActivated{
    top:-5vw;
    display: none;
  }

  .projectActivated:hover{
    transform: translatey(60px);
    transition: 0s;
  }

  h2{
    font-family: Mondwest;
    font-size: 8.7vw;
    font-weight: 100;
    margin-top: var(--main-padding);
    margin-bottom: var(--main-padding);
    padding-top: var(--main-padding);
  }

  #spanProject1{
    margin-top: 0px;
    position: absolute;
  }
  #spanProject2{
    margin-top: 0px;
    position: absolute;
  }

  .card{
    width: calc((100vw/12)*11);
    /* border: 1px solid  var(--main-white); */
    overflow: hidden;
    display:inline-block;
    margin: 3.8vw;
  }

  .thumbnail{
    border-radius:32px;
    width: calc((100vw/12)*11);
    height: calc((100vw/12)*11);
  }

  .thumbnail-back{
    position: absolute;
    border-radius:32px;
    width: calc((100vw/12)*11);
    height: calc((100vw/12)*11);
    background-size: cover;    
    transition-duration: 200ms;
    transform: scale(1, 1);
    border: 1px solid  #05101D;
  }

  .thumbnail-front{
    position: absolute;
    border-radius:32px;
    width: calc((100vw/12)*11);
    height: calc((100vw/12)*11);
    background-size: cover;    
    transition-duration: 200ms;
    transform: scale(1, 1);
    pointer-events: none;
    border: 1px solid  #05101D;
  }

  .projectTag{
    font-size: calc(6vw / 1.3);
    padding-left: var(--main-padding);
    padding-right: var(--main-padding);
    margin-top: var(--main-padding);
    margin-bottom: calc(var(--main-padding)*5);
    margin-right: var(--main-padding);
    border-radius:32px;
    font-family: SpaceMono;
    border: 1px solid  var(--main-white);
    display: inline-block;
    width: auto;
  }

  

  /* lol */

  /* .project{
    padding-left: var(--big-padding);
    border-top: 1px solid;
    position:relative;
    width: 100%;
    background-color:  var(--main-black);
    display: inline-block;
    transform: translatey(60px);
  }

  .projectActivated{
    padding-left: var(--big-padding);
    border-top: 1px solid;
    position:relative;
    width: 100%;
    background-color:  var(--main-black);
    transition: 0.5s;
    transform: translatey(60px);
  }

  .projectActivated:hover{
    transform: translatey(0px);
    transition: 0.1s;
  }

  .projectTitle{
    
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translatey(0px);
  }

  .projectTag{
    font-size: 22px;
    padding-left: var(--main-padding);
    padding-right: var(--main-padding);
    margin-bottom: 10px;
    margin-right: var(--main-padding);
    border-radius:32px;
    font-family: SpaceMono;
    border: 1px solid  var(--main-white);
    display: inline-block;
    width: auto;
  } */



  #number{
    top:-3.8vw;
    font-size:3vw;
  }


#mainText{
    padding-top: 10vw;
    height: 80vw;
}
#illustration{
    height: 110vw;
}

#projects {
    height: auto;
    padding-top: 4vw;
}
#word4{margin-left:-6vw}
#word5{padding-top:7vw}

#footer2 {

    border-top: 0px solid;
    border-left: 0px solid;
  }
  #word9{
    margin-top: 5vw;
  }

  #social{
    text-decoration:underline; 
    padding-right: 2vw;
  }
  #footer3{
    font-size:2vw;
  }

}

/* tiny phones */
@media only screen and (max-width: 380px) 
{
h1 {
  font-size:7vw;
}

.Clickable{font-size:7vw;}
.Clickable:hover{font-size:7vw;}
.Clickable:visited{font-size:7vw;}

:root {
--main-padding: 0.3rem;
--big-padding: 1.5rem;
}

.word-4, .word-5, .word-6 {
margin-top: -3vw;
}


p{
  font-size: 3vw;
  line-height: 1vw;
}

#word-2.1{
  margin-left:-2vw;
}

}