/* 
    This CSS file was created by OwL for use by pointer.js, 
     witch can be found on https://seattleowl.com. 
*/

#pointer-dot {
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 0px solid white;
    position: fixed;
    border-radius: 0px;
    z-index: 101;
    pointer-events: none;
}

#pointer-ring {
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    padding: 15px;
    border: 2px solid #750c7e;
    background-color: #fff;
    mix-blend-mode: difference;
    position: fixed;
    border-radius: 100px;
    z-index: 102;
    pointer-events: none;
    
}

html {
    cursor: none;
}

a {
    cursor: none;
}

@media only screen and (max-width: 900px) 
{
    #pointer-dot {
        border: 0px solid white;
    }
    
    #pointer-ring {
        border: 0px solid #750c7e;
        background-color: #ffffff00;
        border-radius: 0px;
        padding: 0px;
    } 

    html {
        cursor: auto;
    }
    
    a {
        cursor: auto;
    }

}
